import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { DividerComponent } from 'shared/divider';
import { ImageHeaderComponent } from 'shared/image-header';
import { ImageZoomComponent } from 'shared/image-zoom';

@Component({
  selector: 'rs-kezdolap',
  templateUrl: './kezdolap.component.html',
  styleUrls: ['./kezdolap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DividerComponent, ImageHeaderComponent, ImageZoomComponent, TranslateModule]
})
export class KezdolapComponent implements OnInit {
  constructor(
    private metaService: Meta,
    private router: Router,
    private titleService: Title,
  ) { }

  ngOnInit(): void {
    this.metaService.updateTag({ name: 'title', content: 'Kezdőlap' });
    this.metaService.updateTag({
      name: 'description',
      content:
        'Rail System és edilon)(sedra típusú vasúti útátjárók, keretelemek, bordás utófeszített lemezek, buszöblök, peron- és egyéb betonelemek tervezése és beépítése.',
    });
    this.metaService.updateTag({ name: 'site', content: 'Rail System' });

    this.titleService.setTitle('Rail System | Vasúti útátjárók, betonelemek tervezése és kivitelezése');
  }

  public navigateTo(url: string): void {
    this.router.navigate(['/' + url]);
  }
}
