import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MatTabsModule } from '@angular/material/tabs';

import { TranslateModule } from '@ngx-translate/core';

import { DividerComponent } from 'shared/divider';
import { ImageHeaderComponent } from 'shared/image-header';
import { ImageZoomComponent } from 'shared/image-zoom';
import { PartnerLogoComponent } from 'shared/partner-logo';

@Component({
  selector: 'rs-vasuti-utatjaro',
  templateUrl: './vasuti-utatjaro.component.html',
  styleUrls: ['./vasuti-utatjaro.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DividerComponent,
    ImageHeaderComponent,
    ImageZoomComponent,
    MatTabsModule,
    PartnerLogoComponent,
    TranslateModule,
  ]
})
export class VasutiUtatjaroComponent implements OnInit {
  constructor(
    private metaService: Meta,
    private titleService: Title,
  ) { }

  ngOnInit(): void {
    this.metaService.updateTag({ name: 'title', content: 'Vasúti útátjáró' });
    this.metaService.updateTag({
      name: 'description',
      content: 'Hosszú élettartamú, előregyártott betonelemes, rugalmas kitöltőanyagú vasúti útátjárók kivitelezése.',
    });
    this.metaService.updateTag({
      name: 'site',
      content: 'Rail System | Vasúti útátjáró',
    });

    this.titleService.setTitle('Rail System | Vasúti útátjáró');
  }
}
