<rs-image-header
  [headerTitle]="'Egyéb munkák'"
  [imageTitle]="'Egyéb munkák'"
  [imageUrl]="'/assets/img/egyeb_munkak_00.jpg'"
>
</rs-image-header>

<rs-divider></rs-divider>

<mat-tab-group animationDuration="0ms" mat-align-tabs="center" mat-stretch-tabs="false" class="mat-elevation-z2">
  <mat-tab [label]="'GLOBAL.LEIRAS' | translate" i18n="@@leiras">
    <p>{{ 'EGYEB-MUNKAK.LEIRAS' | translate }}</p>
  </mat-tab>
</mat-tab-group>

<rs-divider></rs-divider>

<rs-image-zoom
  [header]="'Lépcsők, Szeged'"
  [imageTitle]="'Lépcsők, Szeged, 2015'"
  [imageUrl]="'/assets/img/egyeb_munkak_01.jpg'"
  [subHeader]="'2015'"
>
</rs-image-zoom>

<rs-divider></rs-divider>

<rs-image-zoom
  [header]="'Peronelem, Szeged'"
  [imageTitle]="'Peronelem, 2015'"
  [imageUrl]="'/assets/img/egyeb_munkak_02.jpg'"
  [subHeader]="'2015'"
>
</rs-image-zoom>

<rs-divider></rs-divider>
