<rs-image-header
  [headerTitle]="'GLOBAL.PERONELEM' | translate"
  [imageTitle]="'Peronelem'"
  [imageUrl]="'/assets/img/peronelem_00.jpg'"
  [subHeaderTitle]="'GLOBAL.TAMFAL' | translate"
>
</rs-image-header>

<rs-divider></rs-divider>

<mat-tab-group animationDuration="0ms" mat-align-tabs="center" mat-stretch-tabs="false" class="mat-elevation-z2">
  <mat-tab [label]="'GLOBAL.LEIRAS' | translate" i18n="@@leiras">
    <p>{{ 'PERONELEM.LEIRAS' | translate }}</p>
  </mat-tab>

  <mat-tab [label]="'GLOBAL.ALKALMAZAS' | translate" i18n="@@alkalmazas">
    <p>{{ 'PERONELEM.ALKALMAZAS' | translate }}</p>
  </mat-tab>

  <mat-tab [label]="'GLOBAL.ELONYEI' | translate" i18n="@@elonyei">
    <div>
      <div>
        <h4>{{ 'PERONELEM.ELONYEI-00' | translate }}</h4>
        <p>{{ 'PERONELEM.ELONYEI-01' | translate }}</p>
      </div>
      <div>
        <h4>{{ 'PERONELEM.ELONYEI-02' | translate }}</h4>
        <p>{{ 'PERONELEM.ELONYEI-03' | translate }}</p>
      </div>
    </div>
  </mat-tab>

  <mat-tab [label]="'GLOBAL.PARTNEREK' | translate" i18n="@@partnerek">
    <div class="partner-logo">
      <rs-partner-logo
        [imageUrl]="'/assets/img/logo_ferrobeton.jpg'"
        [imageTitle]="'Ferrobeton Zrt.'"
        [route]="'https://ferrobeton.hu'"
      >
      </rs-partner-logo>
    </div>
  </mat-tab>
</mat-tab-group>

<rs-divider></rs-divider>

<rs-image-zoom
  [header]="'Leányvár'"
  [imageTitle]="'Leányvár, 2013'"
  [imageUrl]="'/assets/img/peronelem_01.jpg'"
  [subHeader]="'2013'"
>
</rs-image-zoom>

<rs-divider></rs-divider>

<rs-image-zoom
  [header]="'Klotidliget'"
  [imageTitle]="'Klotidliget, 2014'"
  [imageUrl]="'/assets/img/peronelem_02.jpg'"
  [subHeader]="'2014'"
>
</rs-image-zoom>

<rs-divider></rs-divider>
