<rs-image-header
  [headerTitle]="'Rail System'"
  [headerMain]="true"
  [imageTitle]="'Rail System'"
  [imageUrl]="'/assets/img/rail_system_splash.jpg'"
  [subHeaderTitle]="'KEZDOLAP.CIM' | translate"
>
</rs-image-header>

<rs-divider></rs-divider>

<div class="mat-elevation-z5 embed-container">
  <iframe src="https://www.youtube.com/embed/6uu07nMv8Wo?start=6" allowfullscreen></iframe>
</div>

<rs-divider></rs-divider>

<div class="mat-elevation-z5">
  <iframe
    src="https://cdn.knightlab.com/libs/timeline3/latest/embed/index.html?source=1D84XAzpT0fOBF7DYOoxU1gfY9klyaGRCT1EyMN9dtCw&font=OpenSans-GentiumBook&lang=hu&start_at_end=true&initial_zoom=2&height=650"
    width="100%"
    height="650"
    webkitallowfullscreen
    mozallowfullscreen
    allowfullscreen
    frameborder="0"
  ></iframe>
</div>

<rs-divider></rs-divider>

<rs-image-zoom
  [header]="'KEZDOLAP.VASUTI-UTATJARO' | translate"
  [imageTitle]="'Vasúti útátjáró'"
  [imageUrl]="'/assets/img/rail_system_vasuti_utatjaro.jpg'"
  [subHeader]="'Edilon)(sedra || Rail System'"
  (click)="navigateTo('vasuti-utatjaro')"
>
</rs-image-zoom>

<rs-divider></rs-divider>

<rs-image-zoom
  [header]="'KEZDOLAP.KERETELEM' | translate"
  [imageTitle]="'Keretelem'"
  [imageUrl]="'/assets/img/rail_system_keretelem.jpg'"
  [subHeader]="'KEZDOLAP.ALULJARO' | translate"
  (click)="navigateTo('keretelem')"
>
</rs-image-zoom>

<rs-divider></rs-divider>

<div class="mat-elevation-z5 relative">
  <div class="overlay" onClick="style.pointerEvents='none'"></div>
  <iframe
    src="https://www.google.com/maps/d/embed?mid=1YxTHhbY0hOnSp2GSIsv0krvOur_HRHLh"
    width="100%"
    height="520px"
    frameborder="0"
    style="border: 0"
  ></iframe>
</div>

<rs-divider></rs-divider>

<rs-image-zoom
  [header]="'KEZDOLAP.BORDAS-KIEGYENLIITO-LEMEZ' | translate"
  [imageTitle]="'Bordás kiegyenlítő lemez'"
  [imageUrl]="'/assets/img/rail_system_bordas_kiegyenlito_lemez.jpg'"
  (click)="navigateTo('bordas-utofeszitett-lemez')"
>
</rs-image-zoom>

<rs-divider></rs-divider>

<rs-image-zoom
  [header]="'KEZDOLAP.BUSZOBOL' | translate"
  [imageTitle]="'Buszöböl'"
  [imageUrl]="'/assets/img/rail_system_buszobol.jpg'"
  (click)="navigateTo('buszobol')"
>
</rs-image-zoom>

<rs-divider></rs-divider>

<rs-image-zoom
  [header]="'KEZDOLAP.BORDAS-UTOFESZITETT-LEMEZ' | translate"
  [imageTitle]="'Bordás utófeszített lemez'"
  [imageUrl]="'/assets/img/rail_system_bordas_utofeszitett_lemez.jpg'"
  (click)="navigateTo('bordas-kiegyenlito-lemez')"
>
</rs-image-zoom>

<rs-divider></rs-divider>

<rs-image-zoom
  [header]="'KEZDOLAP.PERONELEM' | translate"
  [imageTitle]="'Peronelem'"
  [imageUrl]="'/assets/img/rail_system_peronelem.jpg'"
  [subHeader]="'KEZDOLAP.TAMFAL' | translate"
  (click)="navigateTo('peronelem')"
>
</rs-image-zoom>

<rs-divider></rs-divider>

<rs-image-zoom
  [header]="'KEZDOLAP.EGYEB-MUNKAK' | translate"
  [imageTitle]="'Egyéb munkák'"
  [imageUrl]="'/assets/img/egyeb_munkak_01.jpg'"
  [subHeader]="'KEZDOLAP.EGYEB-MUNKAK' | translate"
  (click)="navigateTo('egyeb-munkak')"
>
</rs-image-zoom>

<rs-divider></rs-divider>

<rs-image-zoom
  [header]="'KEZDOLAP.IMPRESSZUM' | translate"
  [imageTitle]="'Impresszum'"
  [imageUrl]="'/assets/img/rail_system_impresszum.jpg'"
  [subHeader]="'KEZDOLAP.CIKKEK' | translate"
  (click)="navigateTo('impresszum')"
>
</rs-image-zoom>

<rs-divider></rs-divider>
