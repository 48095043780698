import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MatTabsModule } from '@angular/material/tabs';

import { TranslateModule } from '@ngx-translate/core';

import { DividerComponent } from 'shared/divider';
import { ImageHeaderComponent } from 'shared/image-header';
import { ImageZoomComponent } from 'shared/image-zoom';
import { PartnerLogoComponent } from 'shared/partner-logo';

@Component({
  selector: 'rs-bordas-kiegyenlito-lemez',
  templateUrl: './bordas-kiegyenlito-lemez.component.html',
  styleUrls: ['./bordas-kiegyenlito-lemez.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DividerComponent,
    ImageHeaderComponent,
    ImageZoomComponent,
    MatTabsModule,
    PartnerLogoComponent,
    TranslateModule,
  ]
})
export class BordasKiegyenlitoLemezComponent implements OnInit {
  constructor(
    private metaService: Meta,
    private titleService: Title,
  ) { }

  ngOnInit(): void {
    this.metaService.updateTag({
      name: 'title',
      content: 'Bordás kiegyenlítő lemez',
    });
    this.metaService.updateTag({
      name: 'description',
      content: 'Feszített betonlemezek vasúti hidak tehermentesítéséhez, korszerűsítéséhez.',
    });
    this.metaService.updateTag({ name: 'site', content: 'Rail System' });

    this.titleService.setTitle('Bordás kiegyenlítő lemez');
  }
}
