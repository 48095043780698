import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'rs-image-header',
  templateUrl: './image-header.component.html',
  styleUrls: ['./image-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf]
})
export class ImageHeaderComponent {
  @Input() headerMain = false;
  @Input() headerTitle = '';
  @Input() imageTitle = '';
  @Input() imageUrl = '';
  @Input() linkSubheader = '';
  @Input() subHeaderTitle = '';
}
