<rs-image-header
  [headerTitle]="'GLOBAL.IMPRESSZUM' | translate"
  [imageTitle]="'Impresszum'"
  [imageUrl]="'/assets/img/impresszum_00.jpg'"
  [subHeaderTitle]="'GLOBAL.CIKKEK' | translate"
>
</rs-image-header>

<rs-divider></rs-divider>

<mat-tab-group animationDuration="0ms" mat-align-tabs="center" mat-stretch-tabs="false" class="mat-elevation-z2">
  <mat-tab [label]="'IMPRESSZUM.RAIL-SYSTEM-00' | translate">
    <h4>{{ 'IMPRESSZUM.RAIL-SYSTEM-01' | translate }}</h4>
    <p>6800 Hódmezővásárhely, Márton utca 4/A</p>
    <p>{{ 'IMPRESSZUM.RAIL-SYSTEM-02' | translate }} | 8.00 - 18.00</p>
    <p>{{ 'IMPRESSZUM.RAIL-SYSTEM-03' | translate }} | 14063529-2-06</p>
    <p>{{ 'IMPRESSZUM.RAIL-SYSTEM-04' | translate }} | 06-09-011652</p>
    <p>{{ 'IMPRESSZUM.RAIL-SYSTEM-05' | translate }}</p>
    <p>{{ 'GLOBAL.EMAIL-01' | translate }} | +36 70 9 414 898</p>
  </mat-tab>

  <mat-tab [label]="'IMPRESSZUM.TARHELY-00' | translate">
    <h4>Domain-Tarhely.net</h4>
    <p>7342 Mágocs, Széchenyi utca 75.</p>
    <p>{{ 'IMPRESSZUM.TARHELY-01' | translate }} | 0.00 - 24.00</p>
    <p>{{ 'IMPRESSZUM.TARHELY-02' | translate }} | NAIH-78363/2014</p>
    <p>{{ 'IMPRESSZUM.TARHELY-03' | translate }} | 14992511-2-02</p>
    <p>{{ 'IMPRESSZUM.TARHELY-04' | translate }} | 02-09-075023</p>
    <p>info kukac domain-tarhely.net | +36 30 503 1929</p>
  </mat-tab>

  <mat-tab [label]="'GLOBAL.PARTNEREK' | translate" i18n="@@partnerek">
    <div class="partner-logo">
      <rs-partner-logo
        [imageUrl]="'/assets/img/logo_ferrobeton.jpg'"
        [imageTitle]="'Ferrobeton Zrt.'"
        [route]="'https://ferrobeton.hu'"
      >
      </rs-partner-logo>
      <rs-partner-logo
        [imageUrl]="'/assets/img/logo_normalnyomtav.jpg'"
        [imageTitle]="'Normálnyomtáv Kft.'"
        [route]="'https://normalnyomtav.hu'"
      >
      </rs-partner-logo>
      <rs-partner-logo
        [imageUrl]="'/assets/img/logo_edilon_sedra.jpg'"
        [imageTitle]="'edilon)(sedra Bv.'"
        [route]="'https://edilonsedra.com'"
      >
      </rs-partner-logo>
      <rs-partner-logo
        [imageUrl]="'/assets/img/logo_route_consult.jpg'"
        [imageTitle]="'Route Consult Bt.'"
        [route]="'https://routeconsult.hu'"
      >
      </rs-partner-logo>
      <rs-partner-logo
        [imageUrl]="'/assets/img/logo_borsestarsa.jpg'"
        [imageTitle]="'Bors és Társa Bt.'"
        [route]="'https://borsestarsa.hu'"
      >
      </rs-partner-logo>
    </div>
  </mat-tab>
</mat-tab-group>

<rs-divider></rs-divider>

<rs-image-zoom
  [header]="'GLOBAL.VASUTI-UTATJARO' | translate"
  [imageTitle]="'Vasúti útátjáró tervrajz'"
  [imageUrl]="'/assets/img/impresszum_01.jpg'"
>
</rs-image-zoom>

<rs-divider></rs-divider>

<mat-tab-group animationDuration="0ms" mat-align-tabs="center" mat-stretch-tabs="false" class="mat-elevation-z2">
  <mat-tab [label]="'IMPRESSZUM.SZABADALOM-00' | translate">
    <h4>{{ 'IMPRESSZUM.SZABADALOM-01' | translate }}</h4>
    <p>{{ 'IMPRESSZUM.SZABADALOM-02' | translate }}</p>
    <p>{{ 'IMPRESSZUM.SZABADALOM-03' | translate }}</p>
    <h4>{{ 'IMPRESSZUM.SZABADALOM-04' | translate }}</h4>
    <p>{{ 'IMPRESSZUM.SZABADALOM-05' | translate }}</p>
    <p>{{ 'IMPRESSZUM.SZABADALOM-06' | translate }}</p>
    <p>{{ 'IMPRESSZUM.SZABADALOM-07' | translate }}</p>
  </mat-tab>

  <mat-tab [label]="'IMPRESSZUM.CIKKEK-00' | translate">
    <div class="cikkek">
      <a href="/assets/article/2013. - Rail System - Ferrobeton - Civil Pro - Buszmegallok.pdf">
        <mat-icon>picture_as_pdf</mat-icon>
        {{ 'IMPRESSZUM.CIKKEK-02' | translate }}
      </a>

      <a href="/assets/article/2013.11.27. - Rail System - Ferrobeton - Buszobol bemutato.pdf">
        <mat-icon>picture_as_pdf</mat-icon>
        {{ 'IMPRESSZUM.CIKKEK-03' | translate }}
      </a>

      <a href="/assets/article/2017.06. - Rail System - Nagyelemes vasuti atjarok Edilon sinrogzitessel.pdf">
        <mat-icon>picture_as_pdf</mat-icon>
        {{ 'IMPRESSZUM.CIKKEK-04' | translate }}
      </a>

      <a
        href="/assets/article/2018.03.14. - Rail System - Innorail - Novelt hosszusagu atmeneti szakasz alkalmazasa.pdf"
      >
        <mat-icon>picture_as_pdf</mat-icon>
        {{ 'IMPRESSZUM.CIKKEK-05' | translate }}
      </a>

      <a href="/assets/article/2018.11. - Rail System - Innorail - Vasuti atjarok fejlesztesei es epitesuk.pdf">
        <mat-icon>picture_as_pdf</mat-icon>
        {{ 'IMPRESSZUM.CIKKEK-01' | translate }}
      </a>

      <a
        href="/assets/article/2018.11.09. - Rail System - Orisoft - Boltozott vasuti hidak elettartamanak meghosszabbitasa.pdf"
      >
        <mat-icon>picture_as_pdf</mat-icon>
        {{ 'IMPRESSZUM.CIKKEK-06' | translate }}
      </a>

      <a href="/assets/article/2019.10. - Rail System - Innorail - Mobil elemes betonvalyu vedelem.pdf">
        <mat-icon>picture_as_pdf</mat-icon>
        {{ 'IMPRESSZUM.CIKKEK-07' | translate }}
      </a>

      <a href="/assets/article/2022-01 - Rail System - Innorail.pdf">
        <mat-icon>picture_as_pdf</mat-icon>
        {{ 'IMPRESSZUM.CIKKEK-08' | translate }}
      </a>
    </div>
  </mat-tab>
</mat-tab-group>

<rs-divider></rs-divider>
